export class FetchFunctionTypes {
  static readonly type = '[MasterData] Fetch function types';

  constructor() {}
}

export class ResetFunctionTypes {
  static readonly type = '[MasterData] Reset function types';

  constructor() {}
}

export class FetchApplicationRequirementTypes {
  static readonly type = '[MasterData] Fetch application requirement types';

  constructor() {}
}

export class ResetApplicationRequirementTypes {
  static readonly type = '[MasterData] Reset application requirement types';

  constructor() {}
}
