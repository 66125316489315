import { Injectable, inject, OnDestroy } from '@angular/core';
import { AuthService } from './auth.service';
import {
  CreateTenantCommand,
  LinkedTenantResult,
  TenantClient
} from '../../clients/apiClients';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  Subscription,
  switchMap
} from 'rxjs';
import { Store } from '@ngxs/store';
import {
  ResetApplicationRequirementTypes,
  ResetFunctionTypes
} from '../../shared/master-data.actions';
import { Tenant } from '../layout/tenant';
import { filter } from 'rxjs/operators';
import { ResetApplicationImplementationTypeList } from '../../shared/application-implementation-type.actions';

@Injectable({
  providedIn: 'root'
})
export class TenantService implements OnDestroy {
  constructor() {
    const permissionSubscription = this.authService
      .getPermissions()
      .pipe(
        filter((permissions) => permissions.includes('AccessAll')),
        switchMap(() => this.tenantClient.getLinkedTenant())
      )
      .subscribe((tenant) => {
        this.tenantSubject.next(tenant);
      });

    this.subscription.add(permissionSubscription);
  }

  private authService = inject(AuthService);
  private tenantClient = inject(TenantClient);
  private store = inject(Store);

  tenantSubject: BehaviorSubject<LinkedTenantResult | null> =
    new BehaviorSubject<LinkedTenantResult | null>(null);

  subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  listTenants() {
    return this.tenantClient.listTenants();
  }

  create(name: string) {
    return this.tenantClient.create(
      CreateTenantCommand.fromJS({ tenantName: name })
    );
  }

  selectLinkedTenant(id: number) {
    this.tenantClient
      .startAccess(id)
      .pipe(switchMap(() => this.tenantClient.getLinkedTenant()))
      .subscribe((tenant) => {
        this.tenantSubject.next(tenant);
      });
    this.store.dispatch([
      new ResetFunctionTypes(),
      new ResetApplicationRequirementTypes(),
      new ResetApplicationImplementationTypeList()
    ]);
  }

  deselectLinkedTenant() {
    this.store.dispatch([
      new ResetFunctionTypes(),
      new ResetApplicationRequirementTypes(),
      new ResetApplicationImplementationTypeList()
    ]);
    this.tenantClient.stopAccess().subscribe(() => {
      this.tenantSubject.next(null);
    });
  }

  private getLinkedTenant() {
    return this.tenantSubject.asObservable();
  }

  getTenant(): Observable<Tenant | undefined> {
    return this.authService.getPermissions().pipe(
      switchMap((permissions) => {
        if (permissions.includes('AccessAll')) {
          return this.getLinkedTenant().pipe(
            map((n) =>
              n?.name && n?.dataKey ? new Tenant(n.name, n.dataKey) : undefined
            )
          );
        } else {
          return this.store
            .select((o) => o.app.profile)
            .pipe(
              filter((o) => !!o),
              distinctUntilChanged(),
              map((o) => new Tenant(o.tenantName, o.dataKey))
            );
        }
      })
    );
  }
}
